import React, { useState, useEffect, useRef } from 'react';
import { Progress, Breadcrumb, Select, Input, Table, Descriptions, Button, Typography, Tag, Badge, Switch, Row, Form, Col, List} from 'antd';
import Loading from '../Loading';
import {CheckCircleTwoTone, FileSearchOutlined, FullscreenExitOutlined, FullscreenOutlined, SearchOutlined, WarningTwoTone} from '@ant-design/icons';
import {OrderEntryForm} from './OrderForm';
import OrderSearch from './OrderSearch';
import { detailedCurrencyFormatter } from '../utils';
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { unitDisplayLabels } from '../Settings/OrderSettings';
import Address from '../../models/Address';
const colorPrimary = '#4fad96';

const WarningCell = ({hasWarning, warningMessage, warningDetails }) => {
    const [collapsed, setCollapsed] = useState(true);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div>
            {!hasWarning && <CheckCircleTwoTone twoToneColor='#52c41a' style={{paddingRight: 5}}/>}
            <Typography.Text type={hasWarning ? "warning" : "success"}>{warningMessage}</Typography.Text>
            {warningDetails && warningDetails.length > 0 && (
                <div>
                    {collapsed ? (
                        <a onClick={toggleCollapsed} style={{ marginLeft: 8, fontSize: 12 }}>
                            <SearchOutlined/>Show details
                        </a>
                    ) : (
                        <div>
                            <List
                                dataSource={warningDetails}
                                renderItem={(item, index) => (
                                    <List.Item key={index} style={{ padding: '4px 0'}}>
                                        <Typography.Text style={{fontSize: 12}}>• {item}</Typography.Text>
                                    </List.Item>
                                )}
                                size="small"
                                style={{ marginTop: 8 }}
                            />
                            <a onClick={toggleCollapsed} style={{ marginTop: 8, display: 'inline-block', fontSize: 12 }}>
                                Hide details
                            </a>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};


const Workflow = ({ workflowState, configType, onFinish, onCancel, userData, toggleFullScreen, fullscreen }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formState, setFormState] = useState({});
    const [jsonStringData, setJsonStringData] = useState(null);
    const [validationResults, setValidationResults] = useState(null);
    const [isValidating, setIsValidating] = useState(false);

    useEffect(() => {
        if (workflowState.generatedOrder && configType !== 'training' && currentStep === 0) {
            const newState = {
                ...workflowState.generatedOrder,
                PURCHASE_DATE: workflowState.generatedOrder.PURCHASE_DATE ? dayjs(workflowState.generatedOrder.PURCHASE_DATE) : null,
                REQUESTED_OR_DUE_SHIP_DATE: workflowState.generatedOrder.REQUESTED_OR_DUE_SHIP_DATE ? dayjs(workflowState.generatedOrder.REQUESTED_OR_DUE_SHIP_DATE) : null,
                SHIPPED_ON: workflowState.generatedOrder.SHIPPED_ON ? dayjs(workflowState.generatedOrder.SHIPPED_ON) : null,
                USER_SELECTED_PRODUCT_LINE_ITEMS: workflowState.generatedOrder.PRODUCT_LINE_ITEMS ? workflowState.generatedOrder.PRODUCT_LINE_ITEMS.map((l) => {return { ...l[0], 'association': l[1]['id'], 'all_candidates': l[1]['all_candidates']}}) : [],
                CUSTOMER_MATCHES: {
                    'name': workflowState.generatedOrder.BUYER_CUSTOMER_ENTITY_NAME,
                    'type': 'new', ...workflowState.generatedOrder.CUSTOMER_MATCHES
                },
                SHIP_TO_MATCHES: {
                    'name': workflowState.generatedOrder.SHIP_TO_RECIEVING_ADDRESS ? Address.deserialize(workflowState.generatedOrder.SHIP_TO_RECIEVING_ADDRESS).toString() : null,
                    'type': 'new', ...workflowState.generatedOrder.SHIP_TO_MATCHES,
                    'address': workflowState.generatedOrder.SHIP_TO_RECIEVING_ADDRESS
                }
            }

            setFormState(newState);
        } else if (workflowState.generatedOrder && configType === 'training') {
            const jsonDataString = JSON.stringify(workflowState.generatedOrder, null, 4);
            setJsonStringData(jsonDataString);
            setFormState({'json': jsonDataString});
        }
    }, [workflowState.generatedOrder]);

    const handleJsonInputChange = (e) => {
        setJsonStringData(e.target.value);
        setFormState({'json': e.target.value})
    };

    const handleFormChange = (field, value) => {

        setFormState(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const validateOrder = async () => {
        setIsValidating(true);
        try {
            const response = await fetchThreadsData(userData.accessToken, {
                info_type: 'validate_order',
                order_data: formState,
            });
            
            console.log("Order validation response:", response);
            // Assuming the response structure matches the requirement
            setValidationResults(response);
        } catch (error) {
            console.error("Order validation failed:", error);
        
            // Optionally, set validationResults to include a general error message
            setValidationResults({ general: { has_warning: true, warning_message: "Validation failed. Please try again." } });
        } finally {
            setIsValidating(false);
        }
    };

    const handleProductChange = (lineIndex, field, value) => {
        const updatedProductLineItems = formState.USER_SELECTED_PRODUCT_LINE_ITEMS.map((item, index) => {
            if (['PRODUCT_QUANTITY', 'PRODUCT_PRICE', 'PRODUCT_UNIT'].includes(field) && index === lineIndex) {
                return {...item, [field]: value}
            } else if (index === lineIndex) {
                // association handled specially
                return { ...item, association: field };
            }
            return item;
        });

        setFormState(prevState => ({
            ...prevState,
            USER_SELECTED_PRODUCT_LINE_ITEMS: updatedProductLineItems,
        }));
    };

    const isNextButtonEnabled = () => {
        const currentStepConfig = config.steps[currentStep];
        return currentStepConfig.canSkip || 
               !currentStepConfig.validate || 
               (currentStepConfig.validate && currentStepConfig.validate(formState));
    };

    const handleDeleteLineItem = (lineIndex) => {
        const updatedProductLineItems = formState.USER_SELECTED_PRODUCT_LINE_ITEMS.filter((item, index) => index !== lineIndex);
    
        setFormState(prevState => ({
            ...prevState,
            USER_SELECTED_PRODUCT_LINE_ITEMS: updatedProductLineItems,
        }));
    };

    const handleAddLineItem = () => {
        const newLineItem = { PRODUCT_QUANTITY: 0, PRODUCT_PRICE: 0, association: null}; 
    
        setFormState(prevState => ({
            ...prevState,
            USER_SELECTED_PRODUCT_LINE_ITEMS: [...prevState.USER_SELECTED_PRODUCT_LINE_ITEMS, newLineItem],
        }));
    };

    const jsonTextArea = <Input.TextArea
        style={{ whiteSpace: 'pre', overflowX: 'auto', 'height': '100%'}}
        value={jsonStringData}  // Use the temporary state for the input value
        onChange={handleJsonInputChange}
        placeholder="Loading..."
    />;

    const createTrainingWorkflowConfig = {        
        title: "Training",
        steps: [
            {
                title: 'Input Data',
                content: jsonTextArea,
                canSkip: false,
                validate: () => {
                    try {
                        JSON.parse(jsonStringData);
                        return true;
                    } catch (error) {
                        console.error("Invalid JSON:", error);
                        return false;
                    }
                },
            },
        ],
    };

    const attachWorkflowConfig =  {
        title: "Attach Message to Order",
        steps: [
            {
                title: 'Select Order',
                content: workflowState.selectedMessage ? (
                    <Form layout="vertical">
                        <Form.Item label="Select Order to Attach" name="selectedPO">
                            <OrderSearch userData={userData} navLinks={false} onSelect={(val, opt) => {
                                if (!opt || opt.length === 0) return;
                                setFormState({...formState, 'selected_order_name': opt[0].value, 'selected_order_uuid': opt[0].id})
                            }}/>
                        </Form.Item>
                    </Form>
                ) : <Loading/>,
                canSkip: false,
                onStart: () => {},
                onNext: () => {},
                validate: (formState) => {
                    return formState.selected_order_uuid
                },
            },
            {
                title: 'Review',
                content: workflowState.selectedMessage ? (
                    <div>
                        <Typography.Text strong>Message Summary:</Typography.Text>
                        <Descriptions layout="vertical" bordered size="small" column={1}>
                            <Descriptions.Item label="Subject">{workflowState.selectedThread.Preview}</Descriptions.Item>
                            <Descriptions.Item label="Date">{workflowState.selectedMessage.datetime}</Descriptions.Item>
                        </Descriptions>
                        <Typography.Text strong>Attach to Order:</Typography.Text>
                        <Descriptions layout="vertical" bordered size="small" column={1}>
                            <Descriptions.Item label="Order Name">{formState.selected_order_name}</Descriptions.Item>
                        </Descriptions>
                    </div>
                ) : <Loading/>,
                canSkip: false,
                onStart: () => {
                },
                onNext: () => {}
            },
        ]
    };

    const createOrderWorkflowConfig = {
        title: "Create Order",
        steps: [
            {
                title: 'Save Order',
                content: (
                    <>
                        {
                            (formState.error || (formState.DOCUMENT_TYPE_CLASSIFICATION && formState.DOCUMENT_TYPE_CLASSIFICATION !== 'PURCHASE_ORDER')) &&
                            <Row>
                                <FileSearchOutlined style={{ paddingRight: 10 }} />
                                <Typography.Text> We couldn't find an order here... cancel and try again or enter manually</Typography.Text>
                            </Row>
                        }
                        {
                            <OrderEntryForm
                                formState={formState}
                                handleFormChange={handleFormChange}
                                handleProductChange={handleProductChange}
                                handleAddLineItem={handleAddLineItem}
                                handleDeleteLineItem={handleDeleteLineItem}
                                workflowState={workflowState}
                                fullscreen={fullscreen}
                                userData={userData}
                            />
                        }
                    </>
                ),
                validate: (formState) => {
                    return  formState.USER_SELECTED_PRODUCT_LINE_ITEMS && 
                            formState.USER_SELECTED_PRODUCT_LINE_ITEMS.length > 0 &&
                            formState.USER_SELECTED_PRODUCT_LINE_ITEMS.every(lineItem => lineItem.association) &&
                            formState.USER_SELECTED_PRODUCT_LINE_ITEMS.every(lineItem => lineItem.PRODUCT_QUANTITY) &&
                            formState.CUSTOMER_MATCHES &&
                            formState.SHIP_TO_MATCHES &&
                            (
                                (formState.CUSTOMER_MATCHES.type === 'match' && formState.CUSTOMER_MATCHES.id) ||
                                (formState.CUSTOMER_MATCHES.type === 'new' && formState.CUSTOMER_MATCHES.name)
                            ) &&
                            (
                                (formState.CUSTOMER_MATCHES.type === 'match' && (formState.SHIP_TO_MATCHES.type === 'match' || (formState.SHIP_TO_MATCHES.type === 'new' && formState.SHIP_TO_MATCHES.name))) ||
                                (formState.CUSTOMER_MATCHES.type === 'new' && (formState.SHIP_TO_MATCHES.type === 'new' && formState.SHIP_TO_MATCHES.name))
                            )
                },
                canSkip: false,
                onStart: () => {},
                onNext: () => {}
            },
            {
                title: 'Review',
                content: (
                    (isValidating || !formState || !validationResults) ? (
                        <Loading />
                    ) : (
                        <div>
                            {/* General Validation Warning */}
                            {validationResults.general?.has_warning && (
                                <Row style={{ marginBottom: 16 }}>
                                    <WarningTwoTone twoToneColor="#faad14" style={{ marginRight: 8 }} />
                                    <Typography.Text type="warning">{validationResults.general.warning_message}</Typography.Text>
                                </Row>
                            )}

                            <Descriptions layout="vertical" bordered size="small" column={1}>
                                {formState.DOCUMENT_ID && (
                                    <Descriptions.Item label="Order #">
                                        {formState.DOCUMENT_ID}
                                        {validationResults.DOCUMENT_ID?.has_warning && (
                                            <Typography.Text type="warning" style={{ display: 'block' }}>
                                                {validationResults.DOCUMENT_ID.warning_message}
                                            </Typography.Text>
                                        )}
                                    </Descriptions.Item>
                                )}
                                {formState.PURCHASE_DATE && (
                                    <Descriptions.Item label="Placed On">
                                        {formState.PURCHASE_DATE ? dayjs(formState.PURCHASE_DATE).format('YYYY-MM-DD') : 'N/A'}
                                        {validationResults.PURCHASE_DATE?.has_warning && (
                                            <Typography.Text type="warning" style={{ display: 'block' }}>
                                                {validationResults.PURCHASE_DATE.warning_message}
                                            </Typography.Text>
                                        )}
                                    </Descriptions.Item>
                                )}
                                <Descriptions.Item label="Due On">
                                    {formState.REQUESTED_OR_DUE_SHIP_DATE ? dayjs(formState.REQUESTED_OR_DUE_SHIP_DATE).format('YYYY-MM-DD') : 'ASAP'}
                                    {validationResults.REQUESTED_OR_DUE_SHIP_DATE?.has_warning && (
                                        <Typography.Text type="warning" style={{ display: 'block' }}>
                                            {validationResults.REQUESTED_OR_DUE_SHIP_DATE.warning_message}
                                        </Typography.Text>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                            <br/>
                            <Typography.Text strong>Customer Details</Typography.Text>
                            <Descriptions layout="vertical" bordered size="small" column={1}>
                                <Descriptions.Item label="Customer">
                                    {formState.CUSTOMER_MATCHES?.type === 'new' &&
                                        <Tag color="blue">Create New</Tag>
                                    }
                                    {formState.CUSTOMER_MATCHES?.name}
                                    {validationResults.CUSTOMER_MATCHES?.has_warning && (
                                        <Typography.Text type="warning" style={{ display: 'block' }}>
                                            {validationResults.CUSTOMER_MATCHES.warning_message}
                                        </Typography.Text>
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Ship To">
                                    {(!formState.SHIP_TO_MATCHES?.name || !formState.SHIP_TO_MATCHES?.name.trim()) &&
                                        <Tag color="grey">Skip</Tag>
                                    }
                                    {!(!formState.SHIP_TO_MATCHES?.name || !formState.SHIP_TO_MATCHES?.name.trim()) && formState.SHIP_TO_MATCHES?.type === 'new' &&
                                        <Tag color="blue">Create New</Tag>
                                    }
                                    {formState.SHIP_TO_MATCHES?.name}
                                    {validationResults.SHIP_TO_MATCHES?.has_warning && (
                                        <Typography.Text type="warning" style={{ display: 'block' }}>
                                            {validationResults.SHIP_TO_MATCHES.warning_message}
                                        </Typography.Text>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                            <br/>
                            {validationResults.USER_SELECTED_PRODUCT_LINE_ITEMS && (
                                <Table
                                    dataSource={validationResults.USER_SELECTED_PRODUCT_LINE_ITEMS.map((line, index) => ({
                                        key: index,
                                        product: workflowState.products.find(product => product.uuid === line.association)?.name || 'Unknown Product',
                                        ...line
                                    }))}
                                    columns={[
                                        {
                                            title: 'Product',
                                            dataIndex: 'product',
                                            key: 'product',
                                        },
                                        {
                                            title: 'Quantity',
                                            dataIndex: 'PRODUCT_QUANTITY',
                                            key: 'PRODUCT_QUANTITY',
                                        },
                                        {
                                            title: 'Unit',
                                            dataIndex: 'PRODUCT_UNIT',
                                            key: 'PRODUCT_UNIT',
                                            render: (val) => {
                                                return unitDisplayLabels[val];
                                            }
                                        },
                                        {
                                            title: 'Price',
                                            dataIndex: 'PRODUCT_PRICE',
                                            key: 'PRODUCT_PRICE',
                                            render: (val) => {
                                                return detailedCurrencyFormatter.format(val);
                                            }
                                        },
                                        {
                                            title: 'Validation',
                                            dataIndex: 'warning',
                                            key: 'warning',
                                            render: (_, __, index) => {
                                                const itemWarning = validationResults.USER_SELECTED_PRODUCT_LINE_ITEMS[index]?.has_warning;
                                                const warningMessage = validationResults.USER_SELECTED_PRODUCT_LINE_ITEMS[index]?.warning_message;
                                                const warningDetails = validationResults.USER_SELECTED_PRODUCT_LINE_ITEMS[index]?.warning_detail_items;
                                                return (
                                                    <WarningCell 
                                                        hasWarning={itemWarning}
                                                        warningMessage={warningMessage} 
                                                        warningDetails={warningDetails} 
                                                    />
                                                )
                                            }
                                        }
                                    ]}
                                    pagination={false}
                                    size="small"
                                />
                            )}
                            {formState.NON_PRODUCT_LINE_ITEMS && (
                                <Table
                                    dataSource={formState.NON_PRODUCT_LINE_ITEMS}
                                    columns={[
                                        {
                                            title: 'Description',
                                            dataIndex: 'DESCRIPTION',
                                            key: 'DESCRIPTION',
                                        },
                                        {
                                            title: 'Total',
                                            dataIndex: 'TOTAL',
                                            key: 'TOTAL',
                                            render: (val) => {
                                                return detailedCurrencyFormatter.format(val);
                                            }
                                        },
                                    ]}
                                    pagination={false}
                                    size="small"
                                />
                            )}
                            <Descriptions layout="vertical" bordered size="small" column={1}>
                                {formState.TOTAL_PRICE && (
                                    <Descriptions.Item label="Total">
                                        {detailedCurrencyFormatter.format(formState.TOTAL_PRICE)}
                                        {validationResults.TOTAL_PRICE?.has_warning && (
                                            <Typography.Text type="warning" style={{ display: 'block' }}>
                                                {validationResults.TOTAL_PRICE.warning_message}
                                            </Typography.Text>
                                        )}
                                    </Descriptions.Item>
                                )}
                                {formState.NOTES && (
                                    <Descriptions.Item label="Notes">
                                        {formState.NOTES}
                                        {validationResults.NOTES?.has_warning && (
                                            <Typography.Text type="warning" style={{ display: 'block' }}>
                                                {validationResults.NOTES.warning_message}
                                            </Typography.Text>
                                        )}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </div>
                    )
                ),
                canSkip: false,
                onStart: () => {
                    validateOrder();
                },
                onNext: () => { },
                validate: () => {
                    if (isValidating) return false;
                
                    if (validationResults) {
                        // nor now, line item warnings are allowed to be non-blocking
                        //const hasLineItemErrors = (validationResults?.USER_SELECTED_PRODUCT_LINE_ITEMS || []).some(item => item.has_warning);

                        // Block validation if any key has an error
                        const hasGeneralErrors = Object.keys(validationResults).some(key => validationResults[key] && validationResults[key].has_warning);
                        
                        return !hasGeneralErrors;
                    }
                
                    return true;
                }
            }
            
        ],
    };

    let config;
    if (configType === 'attach') {
        config = (attachWorkflowConfig);
    } else if (configType === 'save_order') {
        config = createOrderWorkflowConfig;
    } else if (configType === 'training') {
        config = createTrainingWorkflowConfig;
    } else config = null;

    console.log("Current Step:", formState, currentStep);

    useEffect(() => {
        if (config === null) return;

        setCurrentStep(0);

        if (config.steps[0].onStart !== undefined) {
            config.steps[0].onStart();
        }
    }, [configType]);

    const handleNext = (args) => {
        const currentStepConfig = config.steps[currentStep];
    
        if (currentStepConfig.canSkip || 
            !currentStepConfig.validate || 
            (currentStepConfig.validate && currentStepConfig.validate(formState))) {
            
            if (currentStep < config.steps.length - 1) {
                const nextStep = currentStep + 1;
                setCurrentStep(nextStep);
    
                if (config.steps[nextStep] && config.steps[nextStep].onStart) {
                    config.steps[nextStep].onStart();
                }
            } else {
                onFinish(formState, args.dismiss);
            }
        } else {
            console.log("Cannot proceed to the next step. Please complete the required fields.");
        }
    };
    
    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleSkip = () => {
        if (config.steps[currentStep].canSkip) {
            handleNext();
        }
    };

    if (config === null) return <Loading/>

    return (
        <div style={{height: 'calc(100vh - 58px)', display: 'flex', flexDirection: 'column' }}>
            <div style={{ overflow: 'auto', flex: 1, padding: 15}}>
                <Row>
                    <Col>
                        <Breadcrumb
                            style={{ paddingRight: 15 }}
                            separator=">"
                            items={[
                                {
                                    title: config.title,
                                },
                                {
                                    title: config.steps[currentStep].title + ` (Step ${currentStep + 1} / ${config.steps.length})`,
                                },
                            ]}
                        />
                    </Col>
                    <Col>
                        <Progress type="line" percent={((currentStep + 1) / config.steps.length) * 100} showInfo={false} style={{ marginRight: 8, width: 100 }} />
                    </Col>
                    <Col flex='auto'></Col>
                    <Col>
                        <Button onClick={toggleFullScreen}>
                            {!fullscreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />} {fullscreen && 'Exit'} Full Screen
                        </Button>
                    </Col>
                </Row>
                {configType === null ? <Loading/> : config.steps[currentStep].content}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10, marginTop: 'auto', boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)' }}>
                <Button danger onClick={onCancel}>
                    Cancel
                </Button>
                <div>
                    {currentStep > 0 && (
                        <Button onClick={handleBack} style={{ marginRight: 8 }}>
                            Back
                        </Button>
                    )}
                    {config.steps[currentStep].canSkip && (
                        <Button onClick={handleSkip} style={{ marginRight: 8 }}>
                            Skip
                        </Button>
                    )}
                    {currentStep === config.steps.length - 1 && (
                        <Button 
                            onClick={() => {handleNext({dismiss: false})}}
                            disabled={!isNextButtonEnabled()}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </Button>
                    )}
                    <Button 
                        onClick={() => {handleNext({dismiss: true})}}
                        type="primary" 
                        disabled={!isNextButtonEnabled()}
                    >
                        {currentStep === config.steps.length - 1 ? 'Save & Complete Task' : 'Next'}
                    </Button>
                </div>
            </div>
        </div>
    );
};


export default Workflow;
