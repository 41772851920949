import {
  Card,
  Table,
  Statistic,
  Dropdown,
  Row,
  Input,
  Col,
  Alert,
  Button,
  Typography,
  Modal,
  Select,
  Tag,
  Checkbox,
  Form,
  Skeleton,
  Tooltip,
  List,
  Tabs,
  InputNumber,
  Switch,
  Drawer
} from 'antd';
import { Link, useNavigation, useLoaderData } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchThreadsData } from '../../endpoints/fetchThreadsData';
import {
  DeleteOutlined,
  SearchOutlined,
  LoadingOutlined,
  SlidersOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  currencyFormatter,
  detailedCurrencyFormatter,
  volumeFormatter,
} from '../utils';
import Loading from '../Loading';
import { useSearchParams, useOutletContext } from 'react-router-dom';
import { fobDisplayLabels, unitDisplayLabels } from '../Settings/OrderSettings';
import dayjs from 'dayjs';
import NewShipToAddressForm from './NewShipToAddressForm';

const colorPrimary = '#4fad96';
const { Text, Title } = Typography;

const isValidEmail = (email) => {
const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
return regex.test(email);
};

const CustomerManager = (props) => {
  const { userData, setHasError } = props;
  const { accessToken } = userData;

  const [
    forReviewCount,
    setForReviewCount,
    products,
    setProducts,
    orderUnit,
    setOrderUnit,
    custConfigs,
    setCustConfigs,
    writeIntegration,
  ] = useOutletContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterText, setFilterText] = useState('');
  const [loading, setLoading] = useState(false);
  const [shipTos, setShipTos] = useState(null);
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState(false);
  const [editingCustomerConfig, setEditingCustomerConfig] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [isMergeModalVisible, setIsMergeModalVisible] = useState(false);
  const [mergeIntoCustomer, setMergeIntoCustomer] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('general');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [onlyOpen, setOnlyOpen] = useState(true);

  useEffect(() => {
      const fetchFreshCustomers = async () => {
          setLoading(true);
          await fetchThreadsData(
              userData.accessToken,
              {'info_type': 'undismissed_count'}, 
          ).then((result) => {
              setCustConfigs(result['cust_configs']);
              setLoading(false);
          })
      }

      fetchFreshCustomers();
  }, []);

  if (!custConfigs || loading) return <Loading style={{ paddingTop: 200 }} />;

  const handleEditCustomerConfig = (config) => {
    setEditingCustomerConfig({
      ...config
    });
    setIsCustomerModalVisible(true);
  };

  const handleGetShipTos = async (custConfig) => {
    await fetchThreadsData(accessToken, {
      info_type: 'get_ship_tos',
      customer_id: custConfig.uuid,
    }).then((r) => {
      // Update the state with the new object to force a re-render
      setShipTos(r['ship_tos']);
    });
  };

  const handleShipToStatusChange = (shipToId, value) => {
      setShipTos(shipTos.map(shipTo => 
          shipTo.id === shipToId 
              ? { ...shipTo, archived: value === 'Inactive', archived_changed: true } 
              : shipTo
      ));
  };

  const shipColumns = [
      {
          title: 'Shipping Addresses',
          dataIndex: 'address',
          key: 'address',
          render: (_, record) => (
              <>
                  {record.archived && <Tag color="yellow">Inactive</Tag>}
                  <Text>{record.address}</Text>
              </>
          ),
      },
      {
          title: 'Is Active',
          key: 'status',
          width: 200,
          render: (_, record) => (
              <Select
                  value={record.archived ? 'Inactive' : 'Active'}
                  onChange={(value) => handleShipToStatusChange(record.id, value)}
                  style={{ width: 120 }}
                  disabled={editingCustomerConfig.archived || writeIntegration}
                  options={[
                      { label: 'Active', value: 'Active' },
                      { label: 'Inactive', value: 'Inactive' },
                  ]}
              />
          ),
      },
  ];

  const handleSaveCustomerConfig = async () => {
      setIsCustomerModalVisible(false);
      const copy = { ...editingCustomerConfig };
      const newCustomerConfigs = { ...custConfigs, [copy.uuid]: { ...copy, isGhost: true } };

      setCustConfigs(newCustomerConfigs);

      const shipTosChangedToInactive = [];
      const shipTosChangedToActive = [];

      (shipTos ?? []).forEach(shipTo => {
          if (shipTo.archived_changed) {
              if (shipTo.archived) {
                  shipTosChangedToInactive.push(shipTo);
              } else {
                  shipTosChangedToActive.push(shipTo);
              }
          }
      });

      await fetchThreadsData(
          accessToken,
          { 
              'info_type': 'customer_config_update', 
              'entity': {...copy, 'ship_tos_changed_to_inactive': shipTosChangedToInactive, 'ship_tos_changed_to_active': shipTosChangedToActive},                
          },
      ).then((r) => {
          // Update the state with the new object to force a re-render
          setCustConfigs({
              ...newCustomerConfigs,
              [copy.uuid]: { ...copy, isGhost: false, web_store_key: r['web_store_key'] },
          });
      });

      setShipTos(null);
      setEditingCustomerConfig(null);
      setActiveTabKey('general');
  };

  const handleCancelCustomerConfig = () => {
    setIsCustomerModalVisible(false);
    setEditingCustomerConfig(null);
    setShipTos(null);
    setActiveTabKey('general');
  };

  const filteredCustomerConfigs = Object.values(custConfigs ? custConfigs : {}).filter(
    (config) =>
      config.name !== 'default' &&
      ((onlyOpen && !config.archived) || !onlyOpen) &&
      config.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const activeCustomersCount = filteredCustomerConfigs.filter(config => !config.archived).length;
  const archivedCustomersCount = filteredCustomerConfigs.filter(config => config.archived).length;  

  const isOkButtonEnabled = () => {
      for (const price of Object.values(editingCustomerConfig.productOverrides || {})) {
          if (price === undefined || price === null || editingCustomerConfig['uuid'] === null) {
          return false;
          }
      }
  
      // Check if customer name is not blank and longer than 3 characters
      if (!editingCustomerConfig.name || editingCustomerConfig.name.length <= 3) return false;

      // Validate contact email
      if (editingCustomerConfig.billing_email && !isValidEmail(editingCustomerConfig.billing_email)) return false;

      // Check if all fields in billing address are filled if any field has a value
      const requiredBillingFields = ['LINE_1', 'LINE_2', 'CITY', 'STATE', 'POSTAL_CODE', 'COUNTRY'];
      const billingAddress = editingCustomerConfig.bill_to;

      if (!billingAddress) return true;
      const anyFieldHasValue = requiredBillingFields.some(field => billingAddress[field] && billingAddress[field].length > 0);
      if (anyFieldHasValue) {
          console.log(requiredBillingFields)

          for (const field of requiredBillingFields) {
              if (!billingAddress[field]) return false;
          }
      }

      return true;
  };

  const handleAddOverride = () => {
    if (products.length > 0 ) {

      let firstProductUuid;

      if (editingCustomerConfig?.productOverrides) {
        // Safely find the first product that doesn't have an override
        const firstProduct = products.find(
          product => product?.uuid && !editingCustomerConfig.productOverrides?.[product.uuid]
        );
  
        if (firstProduct?.uuid) {
          firstProductUuid = firstProduct.uuid;
        } else {
          firstProductUuid = products[0]?.uuid;
        }
      } else {
        firstProductUuid = products[0]?.uuid;
      }

      const newOverrides = {
        ...editingCustomerConfig.productOverrides,
        [firstProductUuid]: { price: 0}, // Use the first product's UUID and a placeholder price of 0
      };
      setEditingCustomerConfig({ ...editingCustomerConfig, productOverrides: newOverrides });
    }
  };

  const handleRemoveOverride = (productId) => {
    const newOverrides = { ...editingCustomerConfig.productOverrides };
    delete newOverrides[productId];
    setEditingCustomerConfig({ ...editingCustomerConfig, productOverrides: newOverrides });
  };

  const bulkMenuItems = [
      {
        key: '1',
        label: `Archive (${selectedRowKeys.length}) Customers`,
        disabled: selectedRowKeys.length === 0 || writeIntegration,
        onClick: () => handleBulkAction('archive'),
      },
      {
        key: '2',
        disabled: selectedRowKeys.length < 2 || writeIntegration,
        label: `Merge (${selectedRowKeys.length}) Customers`,
        onClick: () => handleBulkAction('merge'),
      },
  ];

  const handleBulkAction = async (action) => {
      if (action === 'archive') {
      setIsArchiveModalVisible(true);
      } else if (action === 'merge') {
      setIsMergeModalVisible(true);
      }
  };

  const handleMergeConfirm = async () => {
      const targetCustomerId = mergeIntoCustomer;
      const customersToMerge = selectedRowKeys.filter(
        (key) => key !== targetCustomerId
      );
  
      // Close the modal immediately
      setIsMergeModalVisible(false);
  
      // Set isGhost: true on relevant customers to show skeletons
      const updatedCustConfigs = { ...custConfigs };
      customersToMerge.forEach((key) => {
        if (updatedCustConfigs[key]) {
          updatedCustConfigs[key] = {
            ...updatedCustConfigs[key],
            isGhost: true,
          };
        }
      });
      if (updatedCustConfigs[targetCustomerId]) {
        updatedCustConfigs[targetCustomerId] = {
          ...updatedCustConfigs[targetCustomerId],
          isGhost: true,
        };
      }
      setCustConfigs(updatedCustConfigs);
  
      // Proceed with async operation
      await fetchThreadsData(accessToken, {
        info_type: 'merge_customers',
        target_customer_id: targetCustomerId,
        customer_ids_to_merge: customersToMerge,
      });
  
      // After operation completes, update the state
      const finalCustConfigs = { ...updatedCustConfigs };
      // Remove merged customers
      customersToMerge.forEach((key) => {
        delete finalCustConfigs[key];
      });
      // Set isGhost: false on target customer
      if (finalCustConfigs[targetCustomerId]) {
        finalCustConfigs[targetCustomerId] = {
          ...finalCustConfigs[targetCustomerId],
          isGhost: false,
        };
      }
      setCustConfigs(finalCustConfigs);
  
      // Clear selection
      setSelectedRowKeys([]);
      setMergeIntoCustomer(null);
    };
  
    const handleArchiveConfirm = async () => {
      // Close the modal immediately
      setIsArchiveModalVisible(false);
  
      // Set isGhost: true on selected customers to show skeletons
      const updatedCustConfigs = { ...custConfigs };
      selectedRowKeys.forEach((key) => {
        if (updatedCustConfigs[key]) {
          updatedCustConfigs[key] = {
            ...updatedCustConfigs[key],
            isGhost: true,
          };
        }
      });
      setCustConfigs(updatedCustConfigs);
  
      // Proceed with async operation
      await fetchThreadsData(accessToken, {
        info_type: 'archive_customers',
        customer_ids: selectedRowKeys,
      });
  
      // After operation completes, update the customers
      const finalCustConfigs = { ...updatedCustConfigs };
      selectedRowKeys.forEach((key) => {
        if (finalCustConfigs[key]) {
          finalCustConfigs[key] = {
            ...finalCustConfigs[key],
            archived: true,
            isGhost: false,
          };
        }
      });
      setCustConfigs(finalCustConfigs);
  
      // Clear selection
      setSelectedRowKeys([]);
    };

  const customerColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, record) => {
          return record.archived ? (
              <Text><Tag color="yellow">Inactive</Tag>{record.name}</Text>
          ) : (
            <Link
              to={{
                pathname: '/orders/customer',
                search: `?customer_id=${record.uuid}`,
              }}
              style={{
                color: "#0091AE", // Set the text color to blue
                textDecoration: 'underline', // Underline the text
                cursor: 'pointer', // Change cursor to pointer when hovering
              }}
            >
              {record.name}
            </Link>
          );
      }
    },
    {
      title: 'Sales Owner',
      key: 'owner',
      // allow filtering
      filters: [
        ...new Set(Object.values(custConfigs).filter((c) => c.owner).map((config) => config.owner)),
      ].map((owner) => ({ text: owner, value: owner})).sort((a, b) => a.text.localeCompare(b.text)),
      onFilter: (value, record) => record.owner === value,
      render: (_, record) => 
        record.isGhost ? (
          <Skeleton.Input style={{ width: 100 }} active />
        ) : (
          <>
            {record?.owner ? record.owner : 'N/A'}
          </>
        )
    },
    {
      title: 'Last 90 Day Revenue',
      key: 'quarter_revenue',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.quarter_revenue - b.quarter_revenue,
      render: (_, record) => 
        record.isGhost ? (
          <Skeleton.Input style={{ width: 100 }} active />
        ) : (
          <>
            {currencyFormatter.format(record.quarter_revenue)}
          </>
        )
    },
    {
      title: (
        <Tooltip title="Incoterms are standard rules that describe method of goods transport">
          Incoterms <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'FOB',
      key: 'FOB',
      render: (text, record) =>
        record.isGhost ? (
          <Skeleton.Input style={{ width: 100 }} active />
        ) : (
          fobDisplayLabels[text]
        ),
    },
    {
      title: 'Web Store',
      key: 'web_store',
      render: (_, record) =>
        record.isGhost ? (
          <Skeleton.Input style={{ width: 100 }} active />
        ) : record.web_store ? (
          <>
            <Link
              to={{
                pathname: '/order',
                search: `?web_store_key=${record.web_store_key}`,
              }}
              style={{
                color: "#0091AE", // Set the text color to blue
                textDecoration: 'underline', // Underline the text
                cursor: 'pointer', // Change cursor to pointer when hovering
              }}
            >
              Visit Personalized Store
            </Link>
          </>
        ) : (
          <Tag>Disabled</Tag>
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) =>
        record.isGhost ? (
          <Skeleton.Button style={{ width: 58, height: 32 }} active />
        ) : (
          <Button type="link" onClick={() => handleEditCustomerConfig(record)}>
            Edit
          </Button>
        ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <div style={{ padding: 15, maxHeight: 'calc(100vh - 58px)', overflowY: 'auto'}}>
      {writeIntegration && (
          <Alert
              message="Warning"
              description="You have an integration enabled. Some changes made to customers on this page may be overwritten later by your external system. Archiving and merging actions have been disabled. Contact the Pantry team for more details."
              type="warning"
              showIcon
              style={{ marginBottom: 16 }}
          />
      )}
      <Row gutter={16}>
      {activeCustomersCount > 0 && archivedCustomersCount > 0 ? (
          <>
            <Col span={12}>
              <Card>
                <Statistic
                  title="Active"
                  value={activeCustomersCount}
                  precision={0}
                  suffix={'Customers'}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Statistic
                  title="Archived"
                  value={archivedCustomersCount}
                  precision={0}
                  suffix={'Customers'}
                />
              </Card>
            </Col>
          </>
        ) : activeCustomersCount > 0 ? (
          <Col span={24}>
            <Card>
              <Statistic
                title="Active"
                value={activeCustomersCount}
                precision={0}
                suffix={'Customers'}
              />
            </Card>
          </Col>
        ) : archivedCustomersCount > 0 ? (
          <Col span={24}>
            <Card>
              <Statistic
                title="Archived"
                value={archivedCustomersCount}
                precision={0}
                suffix={'Customers'}
              />
            </Card>
          </Col>
        ) : null}
      </Row>
      <br />
      <Row justify="end" align="middle">
        <Col flex="auto">
          <Input
            placeholder="Filter customers..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            prefix={<SearchOutlined />}
            allowClear
            style={{ width: '300px' }}
          />
        </Col>
        <Col>                    
              <Button
                  style={{marginRight: 15}}
                  icon={<SlidersOutlined />}
                  iconPosition={'end'}
                  onClick={() => setDrawerVisible(!drawerVisible)}                        
              >
                  Advanced Filters
              </Button>
          </Col>
        <Col>
          <Dropdown
              menu={{ items: bulkMenuItems }}
              trigger={['click']}
          >
              <Button 
                  iconPosition={'end'}
                  icon={<CaretDownOutlined 
              />}>
                  Bulk Actions ({selectedRowKeys.length})
              </Button>
          </Dropdown>
        </Col>
      </Row>
      <br />
      <Row>
        <Col flex={24}>
          <Table
            columns={customerColumns}
            dataSource={filteredCustomerConfigs}
            rowSelection={rowSelection}
            rowKey="uuid"
          />
        </Col>
      </Row>
      {editingCustomerConfig && (
        <Modal
          title={'Edit Customer'}
          open={isCustomerModalVisible}
          width={800}
          maskClosable={false}
          closable={false}
          okButtonProps={{ disabled: !isOkButtonEnabled() }}
          footer={[
              <Button key="cancel" onClick={handleCancelCustomerConfig}>
                  Cancel
              </Button>,
              <Button key="submit" type="primary" disabled={!isOkButtonEnabled()} onClick={handleSaveCustomerConfig}>
                  Save
              </Button>,
          ]}
        >
          <Form layout="vertical">
            <Tabs
              defaultActiveKey="general"
              destroyInactiveTabPane={true}
              activeKey={activeTabKey}
              onChange={(key) => {
                setActiveTabKey(key);
                if (key === 'shipping' && shipTos === null) {
                  handleGetShipTos(editingCustomerConfig);
                }
              }}
          >
              <Tabs.TabPane tab="General" key="general">
                <Form.Item label="Name">
                  <Input
                    value={editingCustomerConfig.name}
                    onChange={(e) =>
                      setEditingCustomerConfig({
                        ...editingCustomerConfig,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                      label="Is Active"
                      validateStatus={editingCustomerConfig.archived === undefined ? 'error' : ''}
                      help={editingCustomerConfig.archived === undefined ? 'Please select Active or Inactive' : ''}
                  >
                      <Select
                          value={editingCustomerConfig.archived ? 'Inactive' : 'Active'}
                          disabled={writeIntegration}
                          onChange={(value) => setEditingCustomerConfig({ ...editingCustomerConfig, archived: value === 'Inactive', archived_changed: true })}
                          style={{ width: 200 }}
                          options={[
                              { label: 'Active', value: 'Active' },
                              { label: <Tag color="yellow">Inactive</Tag>, value: 'Inactive' },
                          ]}
                      />
                  </Form.Item>                  
                <Form.Item label="Web Store">
                  <Checkbox
                    name="web_store"
                    checked={editingCustomerConfig.web_store}
                    onChange={(e) =>
                      setEditingCustomerConfig({
                        ...editingCustomerConfig,
                        web_store: e.target.checked,
                      })
                    }
                  >
                    Enabled
                  </Checkbox>
                </Form.Item>
                <Form.Item label="Incoterm">
                  <Select
                    placeholder="Select Incoterm"
                    value={editingCustomerConfig.FOB}
                    onChange={(value) =>
                      setEditingCustomerConfig({
                        ...editingCustomerConfig,
                        FOB: value,
                      })
                    }
                    options={[
                      {
                        label: fobDisplayLabels['destination'],
                        value: 'destination',
                      },
                      { label: fobDisplayLabels['origin'], value: 'origin' },
                    ]}
                  />
                </Form.Item>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>Product Price Overrides</div>
                  <Button type="primary" onClick={handleAddOverride}>
                    Add Price Override
                  </Button>
                </div>
                <Table
                  dataSource={Object.entries(
                    editingCustomerConfig.productOverrides || {}
                  ).map(([productId, override]) => ({
                    productId,
                    price: override.price,
                  }))}
                  columns={[
                    {
                      title: 'Product',
                      dataIndex: 'productId',
                      key: 'productId',
                      render: (id, record) => (
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          value={record.isNew ? undefined : id}
                          onChange={(value) => {
                            const newOverrides = {
                              ...editingCustomerConfig.productOverrides,
                            };
                            if (record.isNew) {
                              delete newOverrides[undefined];
                            } else {
                              delete newOverrides[id];
                            }
                            newOverrides[value] = record.price;
                            setEditingCustomerConfig({
                              ...editingCustomerConfig,
                              productOverrides: newOverrides,
                            });
                          }}
                          style={{ width: 340 }}
                          placeholder="Select a product"
                          options={products.map((product) => {
                            return {
                              value: product.uuid,
                              label: product.name,
                            };
                          })}
                        />
                      ),
                    },
                    {
                      title: `Override Price (${unitDisplayLabels[orderUnit]})`,
                      dataIndex: 'price',
                      key: 'price',
                      render: (price, record) => (
                        <InputNumber
                          value={price}
                          onChange={(value) => {
                            const newOverrides = {
                              ...editingCustomerConfig.productOverrides,
                            };
                            newOverrides[record.productId] = { price: value }; // Update the price
                            setEditingCustomerConfig({
                              ...editingCustomerConfig,
                              productOverrides: newOverrides,
                            });
                          }}
                          min={0}
                          formatter={(value) =>
                            `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          style={{ width: '100%' }}
                        />
                      ),
                    },
                    {
                      title: 'Action',
                      key: 'action',
                      render: (_, record) => (
                        <Button
                          danger
                          onClick={() => handleRemoveOverride(record.productId)}
                        >
                          <DeleteOutlined />
                        </Button>
                      ),
                    },
                  ]}
                  rowKey="productId"
                  pagination={false}
                />
                <br/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Billing" key="billing">
                  <Form.Item
                          label="Billing Contact Email"
                          validateStatus={editingCustomerConfig.billing_email && !isValidEmail(editingCustomerConfig.billing_email) ? 'error' : ''}
                          help={editingCustomerConfig.billing_email && !isValidEmail(editingCustomerConfig.billing_email) ? 'Please enter a valid email address' : ''}
                  >  
                      <Input
                          value={editingCustomerConfig.billing_email}
                          onChange={(e) =>
                              setEditingCustomerConfig({
                                  ...editingCustomerConfig,
                                  billing_email: e.target.value,
                              })
                          }
                      />
                  </Form.Item>
              <Text>Billing Address</Text>
              <NewShipToAddressForm
                  newAddressForm={editingCustomerConfig.bill_to}
                  handleAddressChange={(e, key) => {
                      setEditingCustomerConfig({
                          ...editingCustomerConfig,
                          bill_to: {
                              ...editingCustomerConfig.bill_to,
                              [key]: e.target.value,
                          },
                      });
                  }}
                  requiredFieldLabel={(label) => (
                      <Text>
                          <span style={{ color: 'red' }}>*</span> {label}{' '}
                      </Text>
                  )}
              />
              <br/>
              <Form.Item label="Payment Terms">
                  <InputNumber
                      placeholder="Discount %"
                      min={0}
                      max={100}
                      value={editingCustomerConfig.terms?.discount}
                      onChange={(value) =>
                      setEditingCustomerConfig({
                          ...editingCustomerConfig,
                          terms: {
                          ...editingCustomerConfig.terms,
                          discount: value,
                          },
                      })
                      }
                      style={{ width: 100 }}
                  />
                  <span style={{ margin: '0 8px' }}>/</span>
                  <InputNumber
                      placeholder="Discount Payment Date"
                      min={0}
                      value={
                      editingCustomerConfig.terms?.discount_due_days
                      }
                      onChange={(value) =>
                      setEditingCustomerConfig({
                          ...editingCustomerConfig,
                          terms: {
                          ...editingCustomerConfig.terms,
                          discount_due_days: value,
                          },
                      })
                      }
                      style={{ width: 100 }}
                  />
                  <span style={{ margin: '0 8px' }}>Net</span>
                  <InputNumber
                      placeholder="Latest Payment Due Date"
                      min={0}
                      value={
                      editingCustomerConfig.terms?.due_days 
                      }
                      onChange={(value) =>
                      setEditingCustomerConfig({
                          ...editingCustomerConfig,
                          terms: {
                          ...editingCustomerConfig.terms,
                          due_days: value,
                          },
                      })
                      }
                      style={{ width: 100 }}
                  />
                </Form.Item>
                {/* descibe payment terms in english */}
                <Text>{editingCustomerConfig.terms?.discount}% discount if paid within {editingCustomerConfig.terms?.discount_due_days} days, otherwise full amount due within {editingCustomerConfig.terms?.due_days} days</Text>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Shipping" key="shipping">
                  {activeTabKey === 'shipping' && (
                      <>
                      {shipTos === null && <Skeleton active />}
                      {shipTos && (
                          <Table
                          columns={shipColumns}
                          dataSource={shipTos}
                          pagination={{ pageSize: 10 }}
                          scroll={{ y: 300 }}
                          rowKey="id"
                          />
                      )}
                      </>
                  )}
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </Modal>
      )}
  {/* Archive Modal */}
    {isArchiveModalVisible && (
      <Modal
        title="Archive Customers"
        open={isArchiveModalVisible}
        onCancel={() => setIsArchiveModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsArchiveModalVisible(false)}
          >
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleArchiveConfirm}>
            Save
          </Button>,
        ]}
      >
        <Text>The following customers will be archived:</Text>
        <List
          dataSource={selectedRowKeys}
          renderItem={(key) => (
            <List.Item key={key}>{custConfigs[key].name}</List.Item>
          )}
        />
      </Modal>
    )}

    {/* Merge Modal */}
    {isMergeModalVisible && (
      <Modal
        title="Merge Customers"
        open={isMergeModalVisible}
        onCancel={() => setIsMergeModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsMergeModalVisible(false)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={mergeIntoCustomer === null}
            onClick={handleMergeConfirm}
          >
            Save
          </Button>,
        ]}
      >
        <Text>Please choose a customer to keep when merging:</Text>
        <Select
          placeholder="Select customer to merge into"
          style={{ width: '100%', marginTop: 16 }}
          onChange={(value) => setMergeIntoCustomer(value)}
        >
          {selectedRowKeys.map((key) => (
            <Select.Option key={key} value={key}>
              {custConfigs[key].name}
            </Select.Option>
          ))}
        </Select>
        <br />
        <br />
        {mergeIntoCustomer && (
          <>
          <Text style={{ marginTop: 16 }}>
              The following customers will be merged into <strong>{custConfigs[mergeIntoCustomer].name}</strong>:
          </Text>
          <List
            dataSource={selectedRowKeys.filter(
              (key) => key !== mergeIntoCustomer
            )}
            renderItem={(key) => (
              <List.Item key={key}>{custConfigs[key].name}</List.Item>
            )}
          />
          </>
        )}
      </Modal>
    )}
      <Drawer
          title="Advanced Filters"
          placement="right"
          width={250}
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
      >
          <Row gutter={16} style={{marginBottom: 30}}>
              <Switch
                  checked={onlyOpen}
                  onChange={setOnlyOpen}
                  checkedChildren="Only Active"
                  unCheckedChildren="All Customers"
              />
          </Row>
      </Drawer>

    </div>
  );
};

export default CustomerManager;