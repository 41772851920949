import React, { useState, useRef, useEffect } from 'react';
import { Modal, Typography, AutoComplete, Input, Spin, Row, Col, Select, Tag, Button } from 'antd';
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import { LoadingOutlined } from "@ant-design/icons";
import TextArea from 'antd/es/input/TextArea';
import Address from '../../models/Address';
import NewShipToAddressForm from './NewShipToAddressForm'; // Import the new component

const { Text, Link } = Typography;
const { Option } = Select;
const colorPrimary = '#4fad96';

const CustomerShipToModal = ({ open, onOk, onCancel, formState, userData, navLinks, external=false }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const searchInputRef = useRef(null);

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [inputValue, setInputValue] = useState(''); 
    const [selectedCustomerSide, setSelectedCustomerSide] = useState('left');
    const [selectedSide, setSelectedSide] = useState('left');
    const [newCustomer, setNewCustomer] = useState(null);
    const [existingShipTo, setExistingShipTo] = useState(null);
    const defaultAddress = { COUNTRY: 'USA' };
    const [newAddressForm, setNewAddressForm] = useState(defaultAddress);
    const [existingShipToOptions, setExistingShipToOptions] = useState([]);
    const [skipShipTo, setSkipShipTo] = useState(false); 

    const abortControllerRef = useRef(null);

    useEffect(() => {
        if (formState) {
            setNewCustomer(formState.CUSTOMER_MATCHES.name);

            if (formState.SHIP_TO_RECIEVING_ADDRESS && newAddressForm === defaultAddress) {
                setNewAddressForm({
                    ...formState.SHIP_TO_RECIEVING_ADDRESS
                });
            }
            
            if (formState.SHIP_TO_MATCHES.type === 'match' && formState.SHIP_TO_MATCHES.id === null) {
                setSkipShipTo(true); // set on intital load if necessary
            }

            if (formState.SHIP_TO_MATCHES.type === 'match' || formState.CUSTOMER_MATCHES.type === 'match') {
                if (dataSource.length === 0)
                    setDataSource([{...formState.CUSTOMER_MATCHES, other_options: formState.SHIP_TO_MATCHES.other_options}]);

                setSelectedCustomer(formState.CUSTOMER_MATCHES.id);
                setExistingShipTo(formState.SHIP_TO_MATCHES.id);
                console.log(formState.SHIP_TO_MATCHES.other_options);
                setExistingShipToOptions(formState.SHIP_TO_MATCHES.other_options.map((o) => {
                    return {
                        'label': o.name,
                        'value': o.id,
                        'archived': o.archived
                    }
                }));

                if (formState.SHIP_TO_MATCHES.type === 'match' && formState.SHIP_TO_MATCHES.id) setSelectedSide('left');
                else if (formState.CUSTOMER_MATCHES.type === 'match') setSelectedSide('right');
            } else {
                setSelectedCustomerSide('right');
            }
        }
    }, [formState]);

    const handleAddressChange = (e, field) => {
        setNewAddressForm(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    };

    const fetchData = async (value) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        setLoading(true);
        setOptions([]);

        try {
            const results = await fetchThreadsData(
                userData.accessToken,
                { info_type: 'search_customer', text: value },
                { signal: abortControllerRef.current.signal }
            );

            const formattedOptions = results['results'].map((item) => ({
                value: item.id,
                label: (
                    <div key={item.id}>
                        <Text>{item.name}</Text>
                    </div>
                ),
            }));
            
            setDataSource([...dataSource, ...results['results']]);
            setOptions(formattedOptions.length ? formattedOptions : [{ value: 'no-results', label: 'No results found', disabled: true }]);
            setIsDropdownOpen(formattedOptions.length > 0);
            setLoading(false);

        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            } else {
                console.error("Failed to fetch search results:", error);
                setOptions([{ value: 'error', label: (<div>Error fetching results. Please try again.</div>), disabled: true }]);
                setLoading(false);
            }
        }
    };

    const handleSearch = (value) => {
        setOptions([]);
        fetchData(value);
    };

    const toggleSkipShipTo = () => {
        // when skipping, the available option should always be "selecting existing instead"
        setSkipShipTo(true);
        setSelectedSide(prevState => 'right');
    };

    const toggleShipToType = () => {
        setSelectedSide(prevSide => (prevSide === 'left' ? 'right' : 'left'));
        setSkipShipTo(false);

        if (!existingShipTo && existingShipToOptions.length > 0) setExistingShipTo(existingShipToOptions[0].value)
    };

    const toggleCustomerSide = () => {
        setSelectedCustomerSide(selectedCustomerSide === 'left' ? 'right' : 'left');
        setSkipShipTo(false);
    };

    const isFormValid = () => {
        const requiredFields = ['LINE_1', 'LINE_2', 'CITY', 'STATE', 'POSTAL_CODE', 'COUNTRY'];
        return requiredFields.every(field => newAddressForm[field] && newAddressForm[field].trim() !== '');
    };
    const newShipToSelected = !(selectedSide === 'left' && selectedCustomerSide === 'left');
    const requiredFieldLabel = (label) => (
        <>
            <span style={{ color: 'red' }}>*</span> {label}
        </>
    );

    return (
        <Modal
            title="Edit Customer & Ship To"
            open={open}
            width={700}
            maskClosable={false} // don't allow close on click out
            okButtonProps={{ disabled: (!isFormValid() && newShipToSelected && !skipShipTo) }}
            onOk={() => {
                const customerMatchType = selectedCustomerSide === 'left' ? 'match' : 'new';
                const customerValue = selectedCustomerSide === 'left' ? selectedCustomer : newCustomer;
                const shipToMatchType = (selectedSide === 'left' && selectedCustomerSide !== 'right') ? 'match' : 'new';
                const shipToValue = (selectedSide === 'left' && selectedCustomerSide !== 'right') ? existingShipTo : (Object.keys(newAddressForm).length > 0 ? Address.deserialize(newAddressForm).toString() : '')

                const customerMatch = dataSource.find(item => item.id === customerValue);
                const shipToMatch = existingShipToOptions.find(option => option.value === existingShipTo);

                onOk({
                    CUSTOMER_MATCHES: {
                        type: customerMatchType,
                        id: customerMatchType === 'match' ? customerMatch?.id : undefined,
                        name: customerMatchType === 'new' ? customerValue : customerMatch?.name,
                        config: customerMatchType === 'new' ? null : customerMatch?.config,
                        archived: customerMatchType === 'new' ? false : customerMatch?.archived
                    },
                    SHIP_TO_MATCHES: {
                        type: skipShipTo ? 'match' : shipToMatchType,
                        id: skipShipTo ? null : (shipToMatchType === 'match' ? shipToMatch?.value : undefined),
                        name: skipShipTo ? null : (shipToMatchType === 'new' ? shipToValue : shipToMatch?.label),
                        address: newAddressForm,
                        archived: skipShipTo ? null : (shipToMatchType === 'new' ? false : shipToMatch?.archived),
                        other_options: customerMatchType === 'match' ? existingShipToOptions.map((o) => {
                            return {
                                'id': o.value, 'name': o.label, 'archived': o.archived 
                            }
                        }) : []
                    }
                });
            }}
            onCancel={onCancel}
        >   
            { !external && <>
            <Row justify="space-between" align="middle" style={{ marginBottom: '15px' }}>
                <Col>
                    {selectedCustomerSide === 'left' ? (
                        <Text>Select Existing Customer</Text>
                    ) : (
                        <Text>Create New Customer</Text>
                    )}
                </Col>
                <Col>
                    <Button type="link" onClick={toggleCustomerSide}>{selectedCustomerSide === 'left' ? 'Create New Instead' : 'Select Existing Instead'}</Button>
                </Col>
            </Row>
            {selectedCustomerSide === 'left' ? (
               <AutoComplete
                    style={{ width: '100%', marginBottom: '15px' }}
                    options={options}
                    notFoundContent={loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
                    value={isFocused ? inputValue : (selectedCustomer ? dataSource.find((r) => r.id === selectedCustomer)?.name : '')}
                    open={isDropdownOpen}
                    onSelect={(value, option) => {
                        setSelectedCustomer(value);
                        const shipTos = dataSource.find((r) => r.id === value).other_options.map((o) => {
                            return {
                                'label': o.name,
                                'value': o.id,
                                'archived': o.archived
                            }
                        });
                        
                        console.log(shipTos);
                        setExistingShipToOptions(shipTos);
                        setExistingShipTo(shipTos.length > 0 ? shipTos[0].value : null);
                        setInputValue(''); // Clear the input value
                        setIsFocused(false);
                        setIsDropdownOpen(false);
                    }}
                    onChange={(value) => {
                        if (isFocused) {
                            setInputValue(value);
                        }
                    }}
                    onFocus={() => {
                        setIsFocused(true);
                        setIsDropdownOpen(true);
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                        setIsDropdownOpen(false);
                    }}
                >
                    <Input.Search
                        ref={searchInputRef}
                        placeholder="Search for customer..."
                        enterButton={
                            <Button type="primary" disabled={!inputValue.trim()}>
                                Search
                            </Button>
                        }
                        size="medium"
                        loading={loading}
                        onSearch={handleSearch}
                    />
                </AutoComplete>
            ) : (
                    <Input
                        style={{ width: '100%', marginBottom: '15px' }}
                        value={newCustomer}
                        onChange={(e) => setNewCustomer(e.target.value)}
                        placeholder="Enter new customer"
                    />
            )}
            </>}
            {(selectedCustomer !== null || newCustomer !== null) && (dataSource.length > 0 || newCustomer !== null) &&
                <div>
                    <Row gutter={16} style={{ marginTop: '10px' }}>
                        <Col span={24}>
                            <Row justify="space-between" align="middle">
                                <Col flex={24}>
                                    {skipShipTo ? <Text>Skip Ship To</Text> : (selectedSide === 'left' && selectedCustomerSide === 'left' ? (
                                        <Text>Select Existing Ship To</Text>
                                    ) : (
                                        <Text>Create New Ship To</Text>
                                    ))}
                                </Col>
                                {selectedCustomerSide === 'left' &&
                                    <>
                                        <Col flex={0}>
                                            {!skipShipTo && <Button type="link" onClick={toggleSkipShipTo}>Skip Ship To</Button>}
                                        </Col>
                                        <Col flex={0}>
                                            <Button type="link" onClick={toggleShipToType}>{(selectedSide === 'left' && selectedCustomerSide === 'left') ? 'Create New Instead' : 'Select Existing Instead'}</Button>
                                        </Col>
                                    </>
                                }
                            </Row>
                            {skipShipTo ? undefined : (selectedSide === 'left' && selectedCustomerSide === 'left' ? (
                                <Select
                                    style={{ width: '100%', marginTop: '10px'}}
                                    value={existingShipTo}
                                    onChange={(value) => setExistingShipTo(value)}
                                    showSearch
                                    optionFilterProp="text"
                                    dropdownRender={menu => (
                                        <div style={{ minWidth: '100%' }}>
                                            {menu}
                                        </div>
                                    )}
                                    options={existingShipToOptions.map(option => ({
                                        ...option,
                                        label: (
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                {option.archived && <Tag color='yellow'>Inactive</Tag>}
                                                {option.label}
                                            </div>
                                        ),
                                        text: option.label
                                    }))}
                                />
                            ) : (
                            <Row gutter={[16, 16]}>
                                <NewShipToAddressForm
                                    newAddressForm={newAddressForm}
                                    handleAddressChange={handleAddressChange}
                                    requiredFieldLabel={requiredFieldLabel}
                                />
                            </Row>
                        ))}
                        </Col>
                    </Row>
                </div>
            }
        </Modal>
    );
};

export default CustomerShipToModal;
